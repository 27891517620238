<template>
    <v-data-table
        :headers="headers"
        :items="paginationItems.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="paginationItems.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.promoCodeActivation.newItem') }}</v-btn>
                    </template>
                    <promo-code-activation-form
                        v-if="dialogLoaded"
                        :options="options"
                        :itemId="editedItem"
                        :promo_code_item_id="$route.params.id"
                        v-on:saved="onSave"
                        v-on:close="close"
                    ></promo-code-activation-form>
                </v-dialog>
            </v-toolbar>
<!--            <v-container>-->
<!--                <v-row>-->
<!--                    <v-col style="min-width: 200px;">-->
<!--                        <user-autocomplete-->
<!--                                v-model="filters.user_id"-->
<!--                                :label="trans('fields.common.user')"-->
<!--                                clearable-->
<!--                        ></user-autocomplete>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-btn-->
<!--                            class="ma-2"-->
<!--                            color="primary"-->
<!--                            outlined-->
<!--                            @click="applyFilters"-->
<!--                    >{{ trans('buttons.common.apply') }}</v-btn>-->
<!--                    <v-btn-->
<!--                            class="ma-2"-->
<!--                            color="orange"-->
<!--                            outlined-->
<!--                            @click="clearFilters"-->
<!--                    >{{ trans('buttons.common.clear') }}</v-btn>-->
<!--                </v-row>-->
<!--            </v-container>-->
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.active_from="{ item }">
            {{ item.active_from | mysqlDate }}
        </template>
        <template v-slot:item.active_to="{ item }">
            {{ item.active_to | mysqlDate }}
        </template>
        <template v-slot:item.user_id="{ item }">
            <user-link v-if="item.user_id" :user="item.user"></user-link>
        </template>
    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import UserLink from "@/components/admin/user/UserLink";
    // import UserAutocomplete from "@/components/admin/user/UserAutocomplete";

    export default {
        name: "PromoCodeActivationTable",
        components: {
            // UserAutocomplete,
            UserLink,
            PromoCodeActivationForm: () => import('@/components/admin/promoCode/PromoCodeActivationForm'),
        },
        mixins: [tableWithPagination],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                promoCodeActivations: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.user"), value: "user_id", sortable: false },
                    { text: this.trans("fields.promoCode.discount"), value: "discount", sortable: false },
                    { text: this.trans("fields.promoCode.period"), value: "period", sortable: false },
                    { text: this.trans("fields.promoCode.activeFrom"), value: "active_from", sortable: false },
                    { text: this.trans("fields.promoCode.activeTo"), value: "active_to", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.promoCodes.activations.index', this.$route.params.id),

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.initialize();
            // this.loadOptions({cpaNetworkScopes: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.promoCodeActivations;
                },
                set(v){
                    this.promoCodeActivations = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
